import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { IconCaretLeft } from 'vtex.styleguide'
import { FormattedMessage } from 'react-intl'

import LocaleSwitcher from './LocaleSwitcher'
import '../../global.css'

class CardFooter extends Component {
  static propTypes = {
    onBack: PropTypes.func,
  }

  render() {
    const { onBack } = this.props
    return (
      <div className="dib w-100 b--light-silver mt8 ph2">
        {onBack ? (
          <div className="dib w-50 v-top pt2">
            <div className="dib pointer mid-gray" onClick={onBack}>
              <span className="mr2 c-muted-1">
                <IconCaretLeft size={10} color="currentColor" />
              </span>
              <span>back</span>
            </div>
          </div>
        ) : null}
        <div className="dib v-top flex justify-between c-muted-2 t-small flex-nowrap-ns flex-wrap">
          <div className="pv3 flex">
            <a
              className="dib link c-muted-2"
              href="https://help.vtex.com/"
              rel="noopener noreferrer"
              target="_blank">
              <FormattedMessage id="admin/footer.help" />
            </a>
            <a
              className="dn link c-muted-2 mh6"
              href="https://help.vtex.com/"
              rel="noopener noreferrer"
              target="_blank">
              <FormattedMessage id="admin/footer.privacy" />
            </a>
            <a
              className="dn link c-muted-2"
              href="https://help.vtex.com/"
              rel="noopener noreferrer"
              target="_blank">
              <FormattedMessage id="admin/footer.terms" />
            </a>
          </div>
          <div className="pv3 link">
            <LocaleSwitcher />
          </div>
        </div>
      </div>
    )
  }
}

export default CardFooter
