import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { calcIconSize } from '../utils/icons'

const iconBase = {
  width: 87,
  height: 32,
}

export default class Eye extends PureComponent {
  static defaultProps = {
    color: 'currentColor',
    size: 48,
  }

  static propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
  }

  render() {
    const { color, size } = this.props
    const newSize = calcIconSize(iconBase, size)

    return (
      <span className="c-emphasis">
        <svg
          width={newSize.width}
          height={newSize.height}
          viewBox="0 0 87 32"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <g
            id="Testes"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd">
            <g
              id="M-360---VTEX-ID"
              transform="translate(-92.000000, -96.000000)"
              fill={color}
              fillRule="nonzero">
              <g id="Group-3" transform="translate(74.000000, 96.000000)">
                <g id="Group-9" transform="translate(18.000000, 0.000000)">
                  <g id="Group-10">
                    <path
                      d="M34.4572066,0 L6.97904403,0 C4.84838437,0 3.48402747,2.24120492 4.48102925,4.1043928 L7.22958022,9.25414813 L2.24677544,9.25414813 C1.60495825,9.25434176 1.01002628,9.58678252 0.678444601,10.1305124 C0.346862926,10.6742422 0.326970319,11.3499948 0.626004674,11.9118988 L9.46677287,28.4683185 C9.78438256,29.0617107 10.4074417,29.4328236 11.0860742,29.4328236 C11.7647068,29.4328236 12.3877659,29.0617107 12.7053756,28.4683185 L15.1064086,23.9960861 L18.1187205,29.6387175 C19.1789074,31.623307 22.0508897,31.6269417 23.1147501,29.6445331 L36.8876281,4.00043811 C37.8611191,2.18668323 36.532763,0 34.4572066,0 Z M22.1140748,10.9566788 L16.175412,22.0165852 C15.9638928,22.4111824 15.549387,22.6579034 15.0979594,22.6579034 C14.6465319,22.6579034 14.232026,22.4111824 14.0205069,22.0165852 L8.13915148,11.0032039 C7.9392152,10.6297256 7.95131963,10.1799709 8.17106145,9.8175503 C8.39080327,9.45512974 8.78615543,9.23286559 9.21329784,9.2316125 L21.066378,9.2316125 C21.4828662,9.23162905 21.8688536,9.44770318 22.0833784,9.800925 C22.2979031,10.1541468 22.3095534,10.592796 22.1140748,10.9566788 Z"
                      id="Shape"
                    />
                    <path
                      d="M63.1194248,11.5871142 L60.1259623,11.5871142 L60.1259623,21.6770645 C60.1255598,21.8684671 59.9678913,22.0235309 59.7732737,22.0239268 L57.4647002,22.0239268 C57.2700827,22.0235309 57.1124142,21.8684671 57.1120117,21.6770645 L57.1120117,11.5871142 L54.1009879,11.5871142 C54.0093842,11.5908189 53.9201649,11.5578526 53.853703,11.4957433 C53.7872412,11.433634 53.749212,11.3476852 53.7482993,11.2575231 L53.7482993,9.47283753 C53.749212,9.38267546 53.7872412,9.2967266 53.853703,9.23461732 C53.9201649,9.17250804 54.0093842,9.13954179 54.1009879,9.1432464 L63.1186931,9.1432464 C63.3132719,9.13427303 63.4786926,9.28152821 63.4889429,9.47283753 L63.4889429,11.2582428 C63.4783038,11.4489724 63.313454,11.5956889 63.1194248,11.5871142 Z"
                      id="Shape"
                    />
                    <path
                      d="M73.8791407,22.1556941 C73.2543761,22.2423549 72.2034672,22.3817343 70.2758128,22.3817343 C67.9731513,22.3817343 65.9387755,21.8076067 65.9387755,18.642322 L65.9387755,12.8649373 C65.9387755,9.6996526 67.9916793,9.14285714 70.2935997,9.14285714 C72.220513,9.14285714 73.2543761,9.28223655 73.8791407,9.36889732 C74.1288983,9.40356163 74.2363608,9.49094457 74.2363608,9.71698475 L74.2363608,11.336819 C74.2359531,11.5288976 74.0762588,11.6845092 73.8791407,11.6849064 L70.1157307,11.6849064 C69.2767823,11.6849064 68.973664,11.9629431 68.973664,12.867826 L68.973664,14.4508294 L73.7368456,14.4508294 C73.9339637,14.4512267 74.093658,14.6068382 74.0940657,14.7989168 L74.0940657,16.4512489 C74.093658,16.6433274 73.9339637,16.798939 73.7368456,16.7993363 L68.973664,16.7993363 L68.973664,18.6430442 C68.973664,19.5472049 69.2767823,19.8259637 70.1157307,19.8259637 L73.8791407,19.8259637 C74.0762588,19.826361 74.2359531,19.9819726 74.2363608,20.1740512 L74.2363608,21.7917189 C74.2363608,22.0163147 74.1288983,22.1210298 73.8791407,22.1556941 Z"
                      id="Shape"
                    />
                    <path
                      d="M86.3955736,22.020329 L83.575558,22.020329 C83.345037,22.020329 83.2382461,21.9483732 83.114516,21.7605685 L80.6693732,17.967777 L78.4540149,21.6770997 C78.3295483,21.8850521 78.2058181,22.0239268 78.0121216,22.0239268 L75.3872755,22.0239268 C75.2097817,22.0239268 75.121403,21.9195909 75.121403,21.7987051 C75.1248374,21.7562843 75.1368533,21.714951 75.1567545,21.6770997 L78.9990166,15.4169431 L75.1169841,9.47267252 C75.0973005,9.44094231 75.0852153,9.40527425 75.0816327,9.36833658 C75.0948178,9.23611249 75.2116672,9.13712886 75.3475051,9.14311485 L78.2028722,9.14311485 C78.3980417,9.14311485 78.5401841,9.31652838 78.6447655,9.47267252 L80.9138875,12.9733233 L83.1100971,9.47267252 C83.1984757,9.31652838 83.3582938,9.14311485 83.5519903,9.14311485 L86.1768364,9.14311485 C86.3126743,9.13712886 86.4295237,9.23611249 86.4427089,9.36833658 C86.4391262,9.40527425 86.4270411,9.44094231 86.4073574,9.47267252 L82.5444737,15.4536406 L86.5730674,21.6770997 C86.6045194,21.7299987 86.6226917,21.7894272 86.6260946,21.8505133 C86.6268311,21.9512514 86.5377159,22.020329 86.3955736,22.020329 Z"
                      id="Shape"
                    />
                    <path
                      d="M50.3664878,9.14505702 C50.2385642,9.14539469 50.1286593,9.23746977 50.1039003,9.36504501 L47.581906,18.853127 C47.5472791,19.0474497 47.4953387,19.1178458 47.3387962,19.1178458 C47.1822536,19.1178458 47.1303132,19.0445165 47.0956863,18.853127 L44.5708065,9.36284513 C44.5460474,9.23526989 44.4361425,9.14319481 44.308219,9.14285714 L41.8259014,9.14285714 C41.7441242,9.14294474 41.6668489,9.18093057 41.6160032,9.24603572 C41.5651576,9.31114086 41.5462908,9.39625975 41.5647567,9.47723889 C41.5647567,9.47723889 44.6451101,20.3585781 44.6790156,20.4656389 C45.0902103,21.7621015 46.0878986,22.3817343 47.3575524,22.3817343 C48.5666092,22.3817343 49.6220089,21.7327697 50.0346464,20.4693054 C50.0837012,20.3226467 53.0955222,9.47650559 53.0955222,9.47650559 C53.1137328,9.39563245 53.0947462,9.31073056 53.043931,9.24580712 C52.9931158,9.18088368 52.9159957,9.14299592 52.8343775,9.14285714 L50.3664878,9.14505702 Z"
                      id="Shape"
                    />
                    <path
                      d="M34.4572066,0 L6.97904403,0 C4.84838437,0 3.48402747,2.24120492 4.48102925,4.1043928 L7.22958022,9.25414813 L2.24677544,9.25414813 C1.60495825,9.25434176 1.01002628,9.58678252 0.678444601,10.1305124 C0.346862926,10.6742422 0.326970319,11.3499948 0.626004674,11.9118988 L9.46677287,28.4683185 C9.78438256,29.0617107 10.4074417,29.4328236 11.0860742,29.4328236 C11.7647068,29.4328236 12.3877659,29.0617107 12.7053756,28.4683185 L15.1064086,23.9960861 L18.1187205,29.6387175 C19.1789074,31.623307 22.0508897,31.6269417 23.1147501,29.6445331 L36.8876281,4.00043811 C37.8611191,2.18668323 36.532763,0 34.4572066,0 Z M22.1140748,10.9566788 L16.175412,22.0165852 C15.9638928,22.4111824 15.549387,22.6579034 15.0979594,22.6579034 C14.6465319,22.6579034 14.232026,22.4111824 14.0205069,22.0165852 L8.13915148,11.0032039 C7.9392152,10.6297256 7.95131963,10.1799709 8.17106145,9.8175503 C8.39080327,9.45512974 8.78615543,9.23286559 9.21329784,9.2316125 L21.066378,9.2316125 C21.4828662,9.23162905 21.8688536,9.44770318 22.0833784,9.800925 C22.2979031,10.1541468 22.3095534,10.592796 22.1140748,10.9566788 Z"
                      id="Shape"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </span>
    )
  }
}
