import React from 'react'
import PropTypes from 'prop-types'

import Logo from '../icons/VTEXLogo'
import CardFooter from './CardFooter'

const Box = props => {
  return (
    <div className="bg-base ph5 pt8-ns pt7 pb5 br3 b--muted-4 ba-ns">
      <div className="ph7-ns ph4 mb3">
        <div className="dib">
          <Logo size={76} />
        </div>
        <div className="mw6 mt7 center">{props.children}</div>
      </div>
      <CardFooter {...props} />
    </div>
  )
}

Box.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Box
